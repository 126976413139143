import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import HeadLabel from "../components/head-label"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function About() {
  return (
    <Layout>
      <Seo
        title="About Us | 株式会社GoodTe | グッテ"
        description="株式会社GoodTeは“つながり”を通して病気とともに歩む人の人生を豊かにすることを経営理念にしています。"
        ogpSiteName="About Us | 株式会社GoodTeコーポレートサイト"
      />

      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto mt-10">
          <HeadLabel label={"メッセージ"} />

          <div className="px-3 pb-8 typography">
            <div className="my-4">
              患者さんと接する中で「病気に人生を奪われた。」という言葉を何回も聞いてきました。
            </div>
            <div className="my-4">
              たとえ生涯治らない病気にかかってしまったとしても、孤独にならず、ご自身が納得できる充実した人生を送ってほしい、その想いからグッテを設立しました。
            </div>
            <div className="my-4">
              現在の医療システムではなかなかカバーすることのできない患者さんの生活面の悩みの解決を通して、病気とともに歩む人の人生を少しでも豊かなものにする支援をしていきます。
            </div>
          </div>

          <HeadLabel label={"会社名の由来"} />
          <div className="px-3 pb-8 typography">
            <div className="text-lg mb-2">It’s our home GoodTe</div>
            <div>
              グッテ(GoodTe)は、患者さんが他の患者さんや専門家、社会と手(Te)を取り合ってつながり、それぞれの“Good”を手(Te)に得られる、そのような場所を作れればという思いからグッテ(GoodTe)という社名にしました。
            </div>
          </div>

          <HeadLabel label={"経営理念"} />
          <div className="px-3 pb-8 typography">
            “つながり”を通して病気とともに歩む人の人生を豊かにする
          </div>

          <div className="flex flex-wrap">
            <div className="my-6 md:w-1/2">
              <div className="flex">
                <div className="w-1/2 px-2 max-w-[200px]">
                  <StaticImage
                    src="../images/miyazaki.jpg"
                    alt="Miyazaki"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="rounded-full"
                  />
                </div>
                <div className="w-1/2 px-2">
                  <div className="text-sm text-neutral-500">代表取締役社長</div>
                  <div className="text-2xl typography">宮﨑 拓郎</div>
                </div>
              </div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                米国登録栄養士(RDN)、公衆衛生学修士(MPH)、中小企業診断士。帝人ファーマ(株)で事業開発等を経験後退職し渡米。2018年ミシガン大学公衆衛生学修士(栄養科学)修了。同大学病院等での勤務を経て米国登録栄養士取得。同大学消化器内科で臨床試験(低フォドマップ食/アプリ等)に従事。在学中に日本で起業し帰国後代表。講談社より書籍2冊を共著で出版。食事指導にも従事。
              </div>
            </div>

            <div className="my-6 md:w-1/2">
              <div className="flex">
                <div className="w-1/2 px-2 max-w-[200px]">
                  <StaticImage
                    src="../images/suzuki.jpg"
                    alt="suzuki"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="rounded-full"
                  />
                </div>
                <div className="w-1/2 px-2">
                  <div className="text-sm text-neutral-500">
                    取締役
                  </div>
                  <div className="text-2xl typography">鈴木 紀之</div>
                </div>
              </div>
              <div className="text-sm typography px-3 mt-4 text-neutral-700">
                経営学修士(MBA)。田辺三菱製薬(株)にて、マーケティング戦略、プロダクトマネージャー、営業等を担当した後、休職し渡米。2018年ミシガン大学経営学修士修了。在学中は、新規メディカルデバイス開発プロジェクトや診断薬・医療機器販売会社へのコンサルタント業務に従事。帰国後、事業開発を担当。2018年9月、宮﨑らとともに株式会社グッテ(旧名：株式会社ジーケア)を創業し、主に事業開発、マーケティングを担当。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FFEEFF] px-3 py-6 mt-8">
        <div className="max-w-4xl mx-auto">
          <HeadLabel label={"会社概要"} />
          <div className="py-3 typography border-solid border-gray-500 border-t-[0.5px] border-b-[0.5px]">
            <div>会社名</div>
            <div className="font-light pt-1">
              株式会社グッテ (旧名：株式会社ジーケア)
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-500 border-b-[0.5px]">
            <div>所在地</div>
            <div className="font-light pt-1">
              101-0027 東京都千代田区神田平河町1番地第3東ビル308
            </div>
            <div className="font-semibold">
              <a
                href="https://goo.gl/maps/maD9UrCvRdpydeB56"
                target="_blank"
                rel="noreferrer noopener"
              >
                Googleマップ →
              </a>
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-500 border-b-[0.5px]">
            <div>設立</div>
            <div className="font-light pt-1">2018年9月28日</div>
          </div>

          <div className="py-3 typography border-solid border-gray-500 border-b-[0.5px]">
            <div>代表者</div>
            <div className="font-light pt-1">代表取締役 宮﨑拓郎</div>
          </div>

          <div className="py-3 typography border-solid border-gray-500 border-b-[0.5px]">
            <div>沿革</div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2018年6月</div>
              <div className="pt-1">
                田辺三菱製薬株式会社のヘルスケアアクセラレータープログラム優秀賞。
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2018年9月</div>
              <div className="pt-1">株式会社ジーケア創業</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2019年6月</div>
              <div className="pt-1">ASAC第8期採択</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2019年7月</div>
              <div className="pt-1">IBD患者向けオンラインコミュニティ『Gコミュニティ』を開始</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2020年2月</div>
              <div className="pt-1">G-STARTUP Incubate Track採択</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2021年9月</div>
              <div className="pt-1">令和2年度第1回東京都創業助成金採択</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2022年3月</div>
              <div className="pt-1">
                社名を株式会社ジーケアから株式会社グッテに変更
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2022年7月</div>
              <div className="pt-1">
              お腹にやさしい低FODMAPプロテイン『FODUP』を共同開発先のSUNAO製薬より販売開始
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2022年11月</div>
              <div className="pt-1">
                お腹の弱い方向けレシピサイト『グッテレシピ』を開始
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-600 pt-1">2023年3月</div>
              <div className="pt-1">
                明治グループのスタートアップ支援プログラム「明治アクセラレーター」第二期に採択
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </Layout>
  )
}
